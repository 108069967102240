import './Main.css'
import { MainProps } from '../../global/types'
import ProjectList from "../ProjectList/ProjectList";
import AboutUs from '../AboutUs/AboutUs';
import References from '../References/References';
import Experts from '../Experts/Experts';
import Contact from '../Contact/Contact';



const Main = (props: MainProps) => {
    const {
        projects,
        aboutUs,
        references,
        experts,
        contact
    } = props;

    return (
        <main>
            <AboutUs aboutUs={aboutUs}/>
            <Experts experts={experts}/>
            <ProjectList projects={projects} />
            <References references={references}/>
            <Contact contact={contact} /> 
        </main>

    )
}
export default Main;