import { ContactProp } from "../../global/types";
import './Contact.css'

const Contact = (props: ContactProp) => {
    const { contact } = props;

    return (
        <div id="contact">
            <h2 className="title">{contact.Header}</h2>
            <div className="d-flex flex-column flex-sm-column flex-lg-row contact-body">
                <iframe
                    title="adress on google map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d781.4149016478973!2d27.138172365790105!3d38.42623067385817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd97ebc95ede5%3A0xb1297cb5a99a2578!2sEGEBIS%20Bilgi%20%C4%B0%C5%9Flem%20Hizmetleri!5e0!3m2!1str!2str!4v1647265149509!5m2!1str!2str"
                    loading="lazy"></iframe>
                <div className="contact-text">
                    <div className="contact-item">
                        <p>
                            <b>{contact.Adress.title}</b>
                            <br/>
                            {contact.Adress.body}
                        </p>
                    </div>
                    <div className="contact-item">
                        <p>
                            <b>{contact.Email.title}</b>
                            <br/>
                            <a href={`mailto:${contact.Email.body}`}>
                            {contact.Email.body}
                            </a>
                        </p>
                    </div>
                    <div className="contact-item">
                        <p>
                            <b>{contact.Phone.title}</b>
                            <br/>
                            <a href={`tel:${contact.Phone.body}`}>
                                {contact.Phone.body}
                            </a>
                        </p>
                    </div>
                    <div className="contact-item">
                        <p>
                            <b>{contact.Fax.title}</b>
                            <br/>
                            {contact.Fax.body}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;