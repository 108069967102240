import { useEffect, useState } from "react";
import { Navbar, Container, Nav, Col } from "react-bootstrap";
import './Header.css'

type variant = "dark" | "light" | "";

const Header = () => {
    const [cVariant, setCVariant] = useState<variant>("")
    const [navLogoHide, setNavLogoHide] = useState(true);
    const labels = [
        'Kaynak yönetimi',
        'Otomasyon',
        'SAP Sistemi',
    ]
    const handleNavChange = (): void => {
        let yPos = window.scrollY;
        // navbar height * ~2
        let viewPortY = window.outerHeight - 150
        if (yPos >= viewPortY) {
            setNavLogoHide(false)
        } else {
            setNavLogoHide(true)
            // setCVariant('dark')
        }
    }

    const scrollTo = (elementId: string | null) => {
        if (elementId) {
            const offsetTop = document.getElementById(elementId)?.offsetTop;
            if (offsetTop)
                // navbar height = 76px
                window.scrollTo(0, offsetTop - 76)
        }
    }


    useEffect(() => {
        document.addEventListener('scroll', handleNavChange)
        return () => {
            document.removeEventListener('scroll', handleNavChange)
        }
    }, [])

    return (
        <header>
            <Navbar style={{backgroundColor: '#fbfafd', boxShadow: navLogoHide ? '':'0 0 5px 0 rgba(0,0,0,0.2) '}} fixed="top" collapseOnSelect expand="lg" bg={cVariant} variant={cVariant}>

                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            src="./logo.png"
                            height="40"
                            style={{opacity: navLogoHide ? 0 : 1}}
                            className="d-inline-block align-top"
                            alt="Egebis Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className={`justify-content-end${cVariant === "dark" ? " bg-main-dark" : " bg-main-light"}`}>
                        <Nav onSelect={scrollTo}>
                            <Nav.Link eventKey="about-us">Hakkımızda</Nav.Link>
                            <Nav.Link eventKey="experts">Uzmanlıklarımız</Nav.Link>
                            <Nav.Link eventKey="projects">Projelerimiz</Nav.Link>
                            <Nav.Link eventKey="references">Referanslarımız</Nav.Link>
                            <Nav.Link eventKey="contact">İletişim</Nav.Link>
                            {/* <Nav.Link eventKey="carreer">Kariyer</Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="slogan-container flex-sm-column-reverse flex-column-reverse flex-md-row">
                <Col md={6}>
                    <div className="slogan-container">
                        <div className="animation-container">
                            <p>Şirketiniz adına</p>
                            <div className="animation-label-box">
                                <ul className="labels">
                                    <p>
                                        {labels.map((label, idx) => {
                                            return <li className="label" key={idx}><b>{label}</b></li>
                                        })}
                                    </p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="d-flex flex-column justify-content-center align-items-center square-logo-section" md={6}>
                    {/* <div className="square rot-45"></div> */}
                    <img
                            src="logo.png"
                            height="50"
                            className="d-inline-block align-top"
                            alt="Egebis Logo"
                        />
                </Col>

            </Container>
        </header>
    )
}


export default Header;
