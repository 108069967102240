import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { ProjectTileProps, ProjectListProps } from "../../global/types";
import './ProjectList.css';

const ProjectTile = (props: ProjectTileProps) => {
    const project = props.project;
    const [open, setOpen] = useState(false);

    const handleMoreInfo = () => {
        setOpen(!open)
    }

    return (
        <div className={`col-9 col-sm-9 col-md-7 col-lg-5 col-xl-3 card${open ? " show" : ""}`}>
            <div className="card-texts">
                <img width="18%" height="auto" src={project.ImageUrl} alt="project icon" />
                <div className="card-main">
                    <p className="card-title">{project.MainHeader}</p>
                </div>
                <Collapse in={open}>
                    <div>
                        <p className="card-subtitle">{project.Header}</p>
                        <ul className="card-more mt-3">
                            {project.Detail.map((detail, idx)=> {
                                return <li key={idx}>{detail}</li>
                            })}
                        </ul>
                    </div>
                </Collapse>
            </div>
            {project.Detail.length !== 0 && (
                <div className="card-more d-flex justify-content-center col-12">
                    <button id="more-info" onClick={handleMoreInfo}>
                        <FontAwesomeIcon size="2x" rotation={open ? 180 : undefined} icon={faAngleDown} />
                    </button>
                </div>
            )}
        </div>
    )
}



const ProjectList = (props: ProjectListProps) => {
    const { projects } = props
    return (
        <div id="projects">
            <h2 className="title">{projects.Header}</h2>
            <div className="d-flex flex-wrap justify-content-center align-content-start cards">
                {projects.Content.map((project, idx) => {
                    return <ProjectTile key={idx} project={project} />
                })}
            </div>
        </div>
    )
}

export default ProjectList;

