import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './components/Main/Main';
import Header from './components/Header/Header';
import DataProvider from './utils/dataProvider';
import {
  BrowserRouter as Router,
  Route,
  Routes,//switch diger adı
} from 'react-router-dom';
import { useState } from "react";
import Footer from './components/Footer/Footer';

function App() {
  const siteData = DataProvider.getSiteData("TR")
  return (

    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={
            <>
              <Header />
            <Main 
            projects={siteData.Project} 
            aboutUs={siteData.AboutUs} 
            references={siteData.Reference}
            experts={siteData.Expert}
            contact={siteData.Connection}
            />
            <Footer />
            </>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
