import { CSSProperties } from "react"

const footerStyle: CSSProperties = {
    backgroundColor: 'var(--main-dark-color)',
    color: "white",
    padding: 15,
    textTransform: 'capitalize'
}

const Footer = () => {
    return (
        <footer style={footerStyle}>
            Copyright &copy; All rights reserved
            <br/>Egebis Bilgi İşlem Çözümleri Tekn. Ltd. Şti 
        </footer>
    )
}

export default Footer