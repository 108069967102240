import OwlCarousel from "react-owl-carousel";
import 'react-owl-carousel'
import './owl.carousel.min.css'
import './owl.theme.default.min.css'
import './References.css'
import { ReferenceProps } from "../../global/types";


const References = (props: ReferenceProps) => {
    const { references } = props;
    console.log(references)
    
    // const handleRedirection = ()

    return (
        <div id="references" className="container-fluid mb-4">
            <h2 className="title">{references.Header}</h2>
            <OwlCarousel 
            loop 
            autoplay 
            draggable
            autoplaySpeed={2000} 
            mouseDrag={true}
            responsiveRefreshRate={500}
            center
            dots={false}
            responsive= {{
                400: {
                    items: 2,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 5,
                }}}
            className="owl-theme">
                {references.Content.map((reference, idx) => {
                    return (
                        <div key={idx} className="item">
                            <a href={reference.Link ? reference.Link : "#"}>
                                <img src={reference.ImageUrl} alt="Reference Logo" />
                            </a>
                        </div>
                    )
                })}
            </OwlCarousel>
        </div>
    )
}

export default References;