import SITE from '../data/site.json'
import UNI from '../data/universities.json'
import CITIES from '../data/cities.json'

type language = "TR" | "EN";

class DataProvider {
    getSiteData(lang: language) {
        return SITE[lang];
    }

    getUniversities(): object {
        return UNI;
    }

    getCities(): object {
        return CITIES
    }


}

export default new DataProvider();