import { ExpertsProp } from "../../global/types";
import './Experts.css'


const Experts = (props: ExpertsProp) => {
    const { experts } = props;
    console.log(experts)
    return (
        <div id="experts">
            <h2 className="title">{experts.Header}</h2>
            <div className="experts">
                {experts.Content.map((expert, idx) => {
                    let isLeft = idx % 2 === 0;
                    return (
                        <>
                            <div className={`image-section expert ${isLeft ? 'left' : 'right'}`}>
                                <img src={expert.ImageUrl} alt="expert" />
                            </div>
                            <div className={`text-section expert ${!isLeft ? 'left' : 'right'}`}>
                                <ul>
                                    {expert.Detail.map((list, idx) => {
                                        return <li key={idx}>{list}</li>
                                    })}
                                </ul>
                            </div>
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default Experts;