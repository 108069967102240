import { AboutUsProps } from '../../global/types'
import './AboutUs.css'

const AboutUs = (props: AboutUsProps) => {
    const aboutUs = props.aboutUs;
    console.log(aboutUs)
    return (
        <div id="about-us" className="about-us flex-column flex-xxxl-row col-9 col-sm-7">
           <h2 className='title title-space'>Hakkımızda</h2> 
           <div className="container"></div>
           <div className="about-text">
               {aboutUs.Content.map((content, idx) => {
                   return <p key={idx}>{content}</p >
               })}
            </div>
        </div>
    )
}


export default AboutUs;